import SlideShowSlideComponent from '@wix/thunderbolt-elements/src/components/SlideShowSlide/viewer/SlideShowSlide';


const SlideShowSlide = {
  component: SlideShowSlideComponent
};


export const components = {
  ['SlideShowSlide']: SlideShowSlide
};

